:root {
  --color-bg: 255, 255, 255;
  --color-bg-alt: 241, 241, 241;
  --color-bg-disabled: 228, 228, 228;
  --color-bg-inverse: 18, 18, 18;
  --color-bg-primary: 231, 242, 246;
  --color-bg-quarternary: 249, 219, 191;
  --color-border: 227, 227, 227;
  --color-border-alt: 220, 220, 220;
  --color-danger: 211, 3, 41;
  --color-danger-alt: 250, 229, 233;
  --color-info: 0, 156, 222;
  --color-info-alt: 229, 245, 251;
  --color-warn: 186, 131, 35;
  --color-warn-alt: 253, 237, 169;
  --color-success: 30, 97, 44;
  --color-success-alt: 226, 248, 226;
  --color-primary: 16, 76, 151;
  --color-primary-alt: 10, 57, 113;
  --color-secondary: 0, 156, 222;
  --color-secondary-alt: 0, 128, 185;
  --color-tertiary: 132, 35, 94;
  --color-tertiary-alt: 96, 27, 69;
  --color-quarternary: 229, 114, 0;
  --color-quarternary-alt: 197, 98, 0;
  --color-white: 255, 255, 255;
  --color-black: 0, 0, 0;
  --color-text: 18, 18, 18;
  --color-text-alt: 102, 102, 102;
  --color-text-disabled: 189, 189, 189;
  --color-text-inverse: 255, 255, 255;
  --color-outline-shadow: rgba(0, 156, 222, 0.3);
  --color-overlay: rgba(29, 29, 29, 0.3);
  --font-sans: 'Proxima Nova';
  --font-serif: 'Georgia';
  --font-mono: 'Menlo';
  --font-headline: 'Loew';
  --font-headline-brand: 'Simple Joys';
}
