@font-face {
  font-family: Proxima Nova;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/light.woff2') format('woff2'),
    url('/modules/assets/fonts/proxima-nova/light.woff') format('woff');
}
@font-face {
  font-family: Proxima Nova;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/regular.woff2') format('woff2'),
    url('/modules/assets/fonts/proxima-nova/regular.woff') format('woff');
}
@font-face {
  font-family: Proxima Nova;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/semibold.woff2') format('woff2'),
    url('/modules/assets/fonts/proxima-nova/semibold.woff') format('woff');
}
@font-face {
  font-family: Proxima Nova;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/bold.woff2') format('woff2'),
    url('/modules/assets/fonts/proxima-nova/bold.woff') format('woff');
}
@font-face {
  font-family: Proxima Nova;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/proxima-nova/extrabold.woff2') format('woff2'),
    url('/modules/assets/fonts/proxima-nova/extrabold.woff') format('woff');
}

@font-face {
  font-family: Loew;
  font-weight: 800;
  font-display: swap;
  src: url('/modules/assets/fonts/loew/heavy.woff2') format('woff2'),
    url('/modules/assets/fonts/loew/heavy.woff') format('woff');
}

@font-face {
  font-family: Loew;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/loew/regular.woff2') format('woff2'),
    url('/modules/assets/fonts/loew/regular.woff') format('woff');
}

@font-face {
  font-family: Simple Joys;
  font-display: swap;
  src: url('/modules/assets/fonts/simple-joys/simple-joys-webfont.woff2') format('woff2'),
    url('/modules/assets/fonts/simple-joys/simple-joys-webfont.woff') format('woff');
}
