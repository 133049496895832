html {
  scroll-behavior: smooth;
}

/* default anchor links styles */
a:not(
    .btn,
    [data-element-id='nav-desktop'] a,
    [data-element-id='nav-mobile'] a,
    [data-element-id='nav-utility'] a
  ) {
  text-decoration: underline;
}

/* hide everything with the hidden attribute */
[hidden] {
  display: none;
}

/*====================================================== 
    OFFERS COMPONENT
======================================================*/
/* hide potential breadcrumb element */

[data-element-id='offers-wrapper'] h1 {
  @apply text-3xl md:text-5xl font-black leading-none font-headline;
}

[data-element-id='offers-wrapper'] h2 {
  @apply text-xl md:text-3xl font-black leading-none font-headline;
}

[data-element-id='offers-wrapper'] ol {
  display: none;
}

[data-element-id='offers-wrapper'] .btn.btn-primary,
.generic-modal .btn.btn-primary {
  @apply text-lg sm:text-xl text-center sm:text-left py-2 px-6 mr-1 sm:w-auto;
}

[data-element-id='offers-wrapper'] .btn.btn-primary {
  @apply mt-4;
}

[data-element-id='offers-wrapper'] .btn.btn-primary-outline {
  @apply text-lg sm:text-xl text-center sm:text-left py-2 px-6 sm:w-auto;
}

/* apply z-index and hide potential breadcrumb element */
.dialog-overlay {
  @apply z-50;
}

/* offers heading */
[data-element-id='offers-heading'] h2 {
  @apply text-base;
}

/* offers body */
[data-element-id='offers-body'] .rounded-full {
  @apply my-3 text-sm;
}

@screen lg {
  [data-element-id='offers-body'] img {
    @apply pr-4;
  }

  [data-element-id='offers-body'] p {
    @apply text-lg;
  }
}

/*====================================================== 
    OSC COMPOSABLE SEARCH FORM
======================================================*/
/* [data-osc-product='search-rooms-button'],
[data-osc-product='search-rates-button'] {
  @apply w-1/2 sm:w-auto;
} */

[data-osc-product='search-rooms-button'] button,
[data-osc-product='search-rates-button'] button {
  @apply px-6 lg:px-8;
}

[data-osc-product='search-rooms-button'] {
  @apply pr-1 md:pr-0;
}

[data-osc-product='search-rates-button'] {
  @apply pl-1 md:pl-0 pr-0 lg:pr-2 rtl:pl-2 rtl:pr-0;
}

[data-osc-product='search-rates-button'] > button > span {
  @apply right-0.5 bottom-0 lg:top-1;
}

[data-osc-product='shop-form-dates'] + div {
  @apply flex-nowrap justify-center;
}

[data-osc-product='shop-form-dates'] {
  @apply mx-0;
}

/*============================================================== 
    OSC SHOP FORM - PROPERTY GROUP STYLES
=============================================================*/

.is-property-group [data-osc-product='search-rooms-button'] button,
.is-property-group [data-osc-product='search-rates-button'] button,
.is-property-group .osc-search-content-wrapper button[type='submit'] {
  @apply text-sm;
}

[data-osc-product='search-rates-second'] div[id^='input-error'] div div {
  @apply mt-1;
}

[data-osc-date-picker] div:last-of-type {
  background-color: transparent !important;
}

[data-osc-shop-form-rooms] div:last-of-type {
  background-color: transparent !important;
}

[data-osc-shop-form-special-rates] div:last-of-type {
  background-color: transparent !important;
}

/* Header shop form styles */

.cp-header-shop-form {
  @apply px-2;
  --border-color: inherit;
}

.cp-header-shop-form .shop-form-btn {
  @apply relative;
}

.cp-header-shop-form .osc-search-content-wrapper {
  @apply max-w-3xl mx-auto;
}

.cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > div {
  border-color: var(--border-color) !important;
}

.cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > div > span {
  @apply pr-4 font-semibold;
}

.cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > div > div {
  @apply items-end;
}

.cp-header-shop-form
  .osc-search-content-wrapper
  > fieldset
  > div
  > button
  > div
  > div
  > span:first-child {
  @apply font-bold;
}

.cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > div {
  @apply px-3;
}

.cp-header-shop-form .osc-search-content-wrapper > fieldset {
  @apply lg:pr-2 justify-center w-auto mx-auto;
}

.cp-header-shop-form .osc-search-content-wrapper > div {
  margin-left: auto;
  margin-right: auto;
  gap: 8px;
}

.cp-header-shop-form .osc-search-content-wrapper > div:last-child {
  @apply !max-w-full;
}

.cp-header-shop-form .osc-search-content-wrapper > div button {
  @apply leading-9;
}

[data-osc-product='shop-form-selection-button'] div span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 14px;
  letter-spacing: normal;
}

/*============================================================== 
    OSC LOGIN COMPONENT - SIGN-IN BUTTON STYLES
=============================================================*/

[data-element-id='honors-header-login'] button {
  @apply border-0 border-transparent rounded-none py-0 px-0 tracking-normal bg-transparent font-normal;
}

[data-element-id='honors-header-login'] button.btn {
  font-size: unset;
}

/* hide default svg */

[data-element-id='honors-header-login'] button > span[aria-hidden='true'] {
  @apply hidden;
}

.skip-to-content:focus {
  cursor: pointer;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  position: fixed;
  background: #000;
  height: auto;
  width: auto;
  z-index: 9999;
  color: #fff;
  padding: 1em 2em;
  text-decoration: none;
}

/*====================================================== 
  SOCIAL MEDIA (JUICER.IO)
======================================================*/
.juicer-feed.slider .slick-next,
.juicer-feed.slider .slick-prev {
  background: rgba(58, 58, 58, 1) !important;
  color: #ffffff !important;
}
.juicer-feed.slider .slick-next:hover,
.juicer-feed.slider .slick-prev:hover,
.juicer-feed.slider .slick-next:focus,
.juicer-feed.slider .slick-prev:focus {
  background: rgba(0, 0, 0, 1) !important;
  color: #ffffff !important;
}
.juicer-feed.slider .slick-next:hover::before,
.juicer-feed.slider .slick-prev:hover::before,
.juicer-feed.slider .slick-next:focus::before,
.juicer-feed.slider .slick-prev:focus::before {
  background: inherit;
  color: #ffffff !important;
}

.juicer-feed.image-grid .j-text,
.juicer-feed.slider .j-text {
  background: rgba(58, 58, 58, 0.95) !important;
}

/*====================================================== 
  SLIDER-STYLE TRUE/FALSE SWITCH (2 Options only) FOR MEETINGS CAPACITY CHART
======================================================*/
.slider-switch > span.switch-option.unselected > span.screen-text {
  font-size: 0;
}

/*====================================================== 
  LANGUAGE SELECTOR
======================================================*/

:root {
  --osc-lang-bg-hover: #104c97;
  --osc-lang-color: #104c97;
  --osc-lang-color-hover: #fff;
}

[data-element-id='language-wrapper'] .animate-pulse {
  @apply my-1;
}

#language-selector p {
  color: var(--language-selector-dropdown-text-colour);
  border-color: var(
    --language-selector-dropdown-divider-colour,
    rgba(var(--color-border), var(--tw-border-opacity))
  );
}

#language-selector ul li {
  position: relative;
  border-color: var(
    --language-selector-dropdown-divider-colour,
    rgba(var(--color-border), var(--tw-border-opacity))
  );
}

#language-selector ul li a span {
  color: var(--language-selector-dropdown-text-colour, var(--osc-lang-color));
}

#language-selector ul li:hover {
  background-color: var(--language-selector-dropdown-bg-colour-hover, var(--osc-lang-bg-hover));
}

#language-selector ul li:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;
  width: 8px;
  height: 100%;
  background-color: var(--language-selector-dropdown-bg-colour-hover, var(--osc-lang-bg-hover));
}

#language-selector ul li:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: -8px;
  width: 8px;
  height: 100%;
  background-color: var(--language-selector-dropdown-bg-colour-hover, var(--osc-lang-bg-hover));
}

#language-selector ul li:hover a span {
  color: var(--language-selector-dropdown-text-colour-hover, var(--osc-lang-color-hover));
}

/*====================================================== 
  ACCORDION (INCLUDING HOTEL POLICIES)
======================================================*/
.accordion-section ul a {
  @apply underline;
}

/*====================================================== 
  EDITORIAL SNIPPET
======================================================*/
[data-element-id='editorial-snippet-content'] .underline {
  @apply !no-underline;
}

/*====================================================== 
  OFFSET COMPONENT
======================================================*/
[data-element-id='offset-component'] figure figcaption a {
  @apply text-white;
}

[data-element-id='offset-component'] figure figcaption:hover a,
[data-element-id='offset-component'] figure figcaption:focus a,
[data-element-id='offset-component'] figure figcaption a:hover,
[data-element-id='offset-component'] figure figcaption a:focus {
  @apply !no-underline;
  text-decoration: none !important;
}

[data-element-id='offset-component'] a.btn span {
  @apply justify-center;
}

/*====================================================== 
  ROOMS FIRST LETTER IN FILTER PILLS
======================================================*/
.filter-results:first-letter {
  text-transform: capitalize;
}

/*====================================================== 
  OTHER STYLES
======================================================*/
h1,
h2,
h3,
h4,
h5,
h6,
h1 > *,
h2 > *,
h3 > *,
h4 > *,
h5 > *,
h6 > *,
div,
p,
span,
ul,
ol,
li,
blockquote {
  font-variant-ligatures: no-common-ligatures;
}

[data-element-id='key-info-content-wrapper'] ul {
  list-style: disc;
  list-style-position: inside;
  padding-left: 1rem;
}

[data-element-id='key-info-content-wrapper'] ol {
  list-style: decimal;
  list-style-position: inside;
  padding-left: 1rem;
}

/* Hilton Chat Icon - move it out of the way of the retail rail "close" button */
#hilton-chat-root button#chat-button {
  width: 2.75rem;
  height: 2.75rem;
}

#hilton-chat-root .mobile-web {
  bottom: 10px;
  right: 10px;
}
